html,
body {
  height: 100%;
  padding: 0;
  margin: 0;
  font-family: 'Roboto', sans-serif;
}

#root {
  height: 100%;
  display: flex;
  flex-flow: column;
  overflow: hidden;
}

* {
  box-sizing: border-box;
}
